html, body, .root {
  margin: 0px;
  padding: 0;
  width :100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: white; /* bisque *//*  #252839; */
}

/*
p {
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  background-color: #495285;
  color: #fff;
  min-height: 10em;
}
p > span {
  opacity: 0.4;
  text-align: center;
}
*/